/* eslint-disable */
import HttpService from '../HttpService'

export default class LayoutMappingService {
  constructor() {
    this._httpService = new HttpService('/sdi/layout/mapping');
  }

  async FindAllByFinancialGroupId(id) {
      return await this._httpService.get(`/financial-group/${id}`);
    }
}
