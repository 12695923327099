<template>
  <v-container fluid class="mx-0 px-0">
    <v-tabs v-model="tab" class="mb-4" color="primary">
      <v-tab key="searchFilters" href="#searchFilters" style="font-size: 16px" aria-label="Nova Pesquisa">
        <v-icon dense class="mr-2">fas fa-search</v-icon>
        Nova Pesquisa
      </v-tab>
      <v-tab key="favoriteFilters" href="#favoriteFilters" style="font-size: 16px" aria-label="Pesquisas Salvas">
        <v-icon dense class="mr-2">far fa-bookmark</v-icon>
        Pesquisas Salvas
      </v-tab>
    </v-tabs>

    <v-tabs-items style="border-radius: 5px;" v-model="tab" grow>
      <v-tab-item key='searchFilters' value='searchFilters'>
        <v-row v-show="!overlay" class="mt-2 text--primary">
          <v-col cols="12" sm="6" md="4" lg="3" class="py-0">
            <label>Somente enviados via FTP</label>
            <v-autocomplete
              class="mt-1"
              placeholder="Selecione"
              outlined
              dense
              color="textPrimary"
              item-color="textPrimary"
              :items="onlyManualProtocols"
              v-model="filterFileMovements.onlyFtpIntegration"
              @input="changeManualProtocol"
              :search-input.sync="onlyFtpIntegrationSearch"
              @change="onlyFtpIntegrationSearch = null"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" class="py-0">
            <label>Nº do Protocolo</label>
            <v-text-field
              class="mt-1"
              v-model="filterFileMovements.protocolNumber"
              v-mask="'##########'"
              type="number"
              min="1"
              @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
              @blur="validateFieldNumber('protocolNumber')"
              color="textPrimary"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" class="py-0">
            <label>Protocolo criado por</label>
            <v-text-field
              v-model.trim="filterFileMovements.createdBy"
              @blur="filterFileMovements.createdBy = formatter.formatToTitleCase(filterFileMovements.createdBy)"
              class="mt-1"
              color="textPrimary"
              outlined
              dense
              :disabled="filterFileMovements.onlyFtpIntegration ? true : false"
              validate-on-blur
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" class="py-0">
            <label>Grupo Financeiro</label>
            <v-autocomplete
              v-model="filterFileMovements.financialGroup"
              class="mt-1"
              placeholder="Selecione"
              item-text="name"
              item-value="id"
              outlined
              dense
              color="textPrimary"
              item-color="textPrimary"
              :items="financialGroups"
              :search-input.sync="financialGroupSearch"
              @change="financialGroupSearch = null"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" class="py-0">
            <label>Status movimentação via arquivo</label>
            <v-autocomplete
              v-model="filterFileMovements.status"
              class="mt-1"
              placeholder="Selecione"
              item-text="name"
              item-value="id"
              outlined
              dense
              color="textPrimary"
              item-color="textPrimary"
              :items="fileMovementStatusFilter"
              deletable-chips
              chips
              small-chips
              clearable
              multiple
              :search-input.sync="statusSearch"
              @change="statusSearch = null"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" class="py-0">
            <label>Somente movimentações com erros</label>
            <v-autocomplete
              v-model="filterFileMovements.withErrors"
              class="mt-1"
              placeholder="Selecione"
              item-text="text"
              item-value="value"
              outlined
              dense
              color="textPrimary"
              item-color="textPrimary"
              :items="onlyWithErrors"
              :search-input.sync="withErrorsSearch"
              @change="withErrorsSearch = null"
            />
          </v-col>        
        </v-row>        
        <v-row v-show="overlay" class="primary--text my-15">
          <v-col cols="12" align="center">
            <v-progress-circular
              color="primary"
              indeterminate
              size="64"
              width="8"
            />
          </v-col>
          <v-col cols=12 align="center">
            Carregando Filtros
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item key='favoriteFilters' value='favoriteFilters'>
        <FavoriteSearchComponent
          filterBy="FileMovementsContentFilter"
          :searchFilter="filterFileMovements"
          :changeTabValue="changeTabValue"
          @loadThisFilter="loadThisFilter"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import MovementRecordsMixin from '@/shared/mixins/movementRecords/movementRecordsMixin';
import FavoriteSearchComponent from '@/components/FavoriteSearchFilters/FavoriteSearchComponent.vue';

export default ({
  name: 'FileMovementsContentFilter',

  components: { FavoriteSearchComponent },

  data: () => ({
    onlyFtpIntegrationSearch: null,
    financialGroupSearch: null,
    statusSearch: null,
    withErrorsSearch: null,
    overlay: false,
    onlyManualProtocols: [
      { text: 'Sim', value: true },
      { text: 'Não', value: false },
    ],
    fileMovementStatus: {
      'CANCELED': 'Cancelado',
      'INVALID': 'Inválido',
      'COLLECTED': 'Aguardando Processamento',
      'CONVERTED': 'Em processamento',
      'FINISHED': 'Processado',
      'REFUSED': 'Recusado',
    },
    onlyWithErrors: [
      { text: 'Sim', value: 'true' },
      { text: 'Não', value: 'false' },
    ],

    tab: 'searchFilters',
    changeTabValue: 'FILE_DATA',
  }),

  mixins: [
    MovementRecordsMixin,
  ],

  props: {
    financialGroups: {
      type: Array,
      default: () => [],
    },
    dates: { type: Object },
    resetFilterFileMovements: { type: Boolean },
  },

  watch: {
    filterFileMovements: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.$emit('hasFiltersFileMovements', this.filterFileMovements);
        }
      },
    },
    resetFilterFileMovements: {
      deep: true,
      handler(value) {
        if (value) {
          this.clearFilterFileMovements();
        }
      },
    },
  },

  created() {
    this.formatter = new Formatters();
    this.rule = new Rules();
  },

  computed: {
    fileMovementStatusFilter() {
      return Object.entries(this.fileMovementStatus).map(
        ([id, name]) => ({ id, name }));
    },
  },

  methods: {
    changeManualProtocol() {
      if (this.filterFileMovements.onlyFtpIntegration) {
        this.filterFileMovements.createdBy = '';
      }
    },
    validateFieldNumber(fieldName) {
      const fieldValue = this.filterFileMovements[fieldName];
      const sanitizedValue = fieldValue.replace(/[^0-9]/g, '');
      if (sanitizedValue === '' || sanitizedValue === 0 || typeof sanitizedValue === 'undefined') {
        this.filterFileMovements[fieldName] = '';
      } else {
        this.filterFileMovements[fieldName] = sanitizedValue.replace(/^0+/, '');
      }
    },
    clearFilterFileMovements() {
      setTimeout(() => {
        this.filterFileMovements = {
          onlyFtpIntegration: false,
          protocolNumber: '',
          createdBy: '',
          financialGroup: '',
          receivingDateStart: '',
          receivingDateEnd: '',
          status: [],
          withErrors: ''
        };
      })
      this.$forceUpdate();
      this.$emit('resetStatusFilters', false);
    },
    loadThisFilter(data) {
      this.setOverlay(true);
      this.filterFileMovements = {
        onlyFtpIntegration: data.queryParams.onlyFtpIntegration === 'true',
        protocolNumber: data.queryParams.protocolNumber ? data.queryParams.protocolNumber : '',
        createdBy: data.queryParams.createdBy ? data.queryParams.createdBy : '',
        financialGroup: data.queryParams.financialGroup ? Number(data.queryParams.financialGroup) : null,
        receivingDateStart: data.queryParams.receivingDateStart ? data.queryParams.receivingDateStart : '',
        receivingDateEnd: data.queryParams.receivingDateEnd ? data.queryParams.receivingDateEnd : '',
        selectedDate: data.queryParams.selectedDate,
        dateType: ['createdAt'],
      };

      this.tab = 'searchFilters';
      this.$emit('hasFiltersFileMovements', this.filterFileMovements, 'getFilterByFavorite');

      setTimeout(() => {
        this.setOverlay(false);
      }, 500);
    },
    setOverlay(value) {
      this.overlay = value;
    },
  },
});
</script>
