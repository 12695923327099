<template style="background: #E5E5E5">
  <div>
    <v-dialog :value="dialog.show" persistent max-width="1080">
      <v-card class="pa-5 modalManualCriticismTopCenter">
        <v-card-title class="headline pl-3 ml-3">
          <v-row>
            <v-col cols="6" style="color: #3b495b; font-weight: bold">
              Crítica Manual
            </v-col>
            <v-col
              cols="6"
              class="d-flex justify-end">
              <v-btn
                icon
                color="primary"
                @click="onClickCloseModal()">
                <v-icon
                  size="36">
                  close
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6" style="color: #4d596a">
              Nome:
              <span style="font-weight: bold">{{ insuredName }}</span>
            </v-col>
            <v-col
              cols="6"
              style="color: #4d596a"
              class="d-flex justify-end">
              <v-btn
                color="primary"
                outlined
                @click="startCreationCarrierCriticism()"
                v-if="!creatingCarrierCriticism">
                <v-icon
                  left
                  dark>
                  fas fa-plus
                </v-icon>
                Criar Crítica
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text class="pb-0 mt-4">
          <v-row>
            <v-col
              xl="10"
              lg="12"
              cols="12">
              <v-autocomplete
                :items="criticsFiltered"
                clearable
                color="textPrimary"
                dense
                item-color="textPrimary"
                label="Crítica"
                :filter="filterCritics"
                @click:clear="cleanCritic"
                outlined
                placeholder="Digite aqui"
                v-if="!creatingCarrierCriticism"
                v-model="carrierCriticism"
                hide-details
                return-object>
                <template
                  v-slot:item="{item}">
                  <v-tooltip color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <div
                          style="min-width: 1400px;">
                          <v-row
                            no-gutters>
                            <v-col
                              cols="3"
                              class="d-flex justify-start"
                              style="max-width: 25%;">
                              <strong
                                class="mr-2">
                                Operadora:
                              </strong>
                              <span
                                class="text-truncate">
                                {{getInsuranceCarrierNameById(item.insuranceCarrierId) || '-'}}
                              </span>
                            </v-col>
                            <v-col
                              cols="3"
                              class="d-flex justify-start"
                              style="max-width: 350px;">
                              <strong
                                class="mr-2">
                                Descrição:
                              </strong>
                              <span
                                class="text-truncate">
                                {{item.description || '-'}}
                              </span>
                            </v-col>
                            <v-col
                              cols="3"
                              class="d-flex justify-start pr-2"
                              style="max-width: 350px;">
                              <strong
                                class="mr-2">
                                Mensagem:
                              </strong>
                              <span
                                class="text-truncate">
                                {{item.message || '-'}}
                              </span>
                            </v-col>
                            <v-col
                              cols="3"
                              class="d-flex justify-start"
                              style="max-width: 25%;">
                              <strong
                                class="mr-2">
                                Classificação:
                              </strong>
                              <span
                                class="text-truncate">
                                {{getClassificationNameById(item.classificationId) || '-'}}
                              </span>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </template>
                    <div
                      style="max-width: 500px;">
                      <v-row
                        no-gutters>
                        <v-col
                          cols="12"
                          class="d-flex justify-start">
                          <strong
                            class="mr-2"
                            style="min-width: 70px">
                            Operadora:
                          </strong>
                          <p>
                            {{getInsuranceCarrierNameById(item.insuranceCarrierId) || '-'}}
                          </p>
                        </v-col>
                        <v-col
                          cols="12"
                          class="d-flex justify-start">
                          <strong
                            class="mr-2"
                            style="min-width: 70px">
                            Campo:
                          </strong>
                          <p
                            class="text-truncate">
                            {{getFieldNameByValue(item.field) || '-'}}
                        </p>
                        </v-col>
                        <v-col
                          cols="12"
                          class="d-flex justify-start">
                          <strong
                            class="mr-2"
                            style="min-width: 70px">
                            Cód. Crítica:
                          </strong>
                          <p
                            class="text-truncate">
                            {{item.criticismCode || '-'}}
                        </p>
                        </v-col>
                        <v-col
                          cols="12"
                          class="d-flex justify-start">
                          <strong
                            class="mr-2"
                            style="min-width: 70px">
                            Cód. Crítica:
                          </strong>
                          <p
                            class="text-truncate">
                            {{item.criticismCode || '-'}}
                        </p>
                        </v-col>
                        <v-col
                          cols="12"
                          class="d-flex justify-start mb-2">
                          <strong
                            class="mr-2"
                            style="min-width: 70px">
                            Descrição:
                          </strong>
                          <p>
                            {{item.description || '-'}}
                          </p>
                        </v-col>
                        <v-col
                          cols="12"
                          class="d-flex justify-start">
                          <strong
                            class="mr-2"
                            style="min-width: 70px">
                            Mensagem:
                          </strong>
                          <p>
                            {{item.message || '-'}}
                          </p>
                        </v-col>
                        <v-col
                          cols="12"
                          class="d-flex justify-start">
                          <strong
                            class="mr-2"
                            style="min-width: 70px">
                            Classificação:
                          </strong>
                          <p>
                            {{getClassificationNameById(item.classificationId) || '-'}}
                          </p>
                        </v-col>
                      </v-row>
                    </div>
                  </v-tooltip>
                </template>

                <template
                  v-slot:selection="{item, index}">
                  <div
                    style="min-width: 1000px;"
                    >
                    <v-row
                      no-gutters
                      style="max-width: 1000px">
                      <v-col
                        cols="12"
                        xl="4"
                        lg="12"
                        class="d-flex justify-start">
                        <strong
                          class="mr-2">
                          Operadora:
                        </strong>
                        <span
                          class="text-truncate">
                          {{getInsuranceCarrierNameById(item.insuranceCarrierId) || '-'}}
                        </span>
                      </v-col>
                      <v-col
                        cols="12"
                        xl="4"
                        lg="12"
                        class="d-flex justify-start">
                        <strong
                          class="mr-2">
                          Descrição:
                        </strong>
                        <span
                          class="text-truncate">
                          {{item.description || '-'}}
                        </span>
                      </v-col>
                      <v-col
                        cols="12"
                        xl="4"
                        lg="12"
                        class="d-flex justify-start text-truncate">
                        <strong
                          class="mr-2">
                          Mensagem:
                        </strong>
                        <span
                          class="text-truncate">
                          {{item.message || '-'}}
                        </span>
                      </v-col>
                    </v-row>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              class="d-flex justify-end">
              <v-btn
                :disabled="!carrierCriticism"
                @click="addCriticism()"
                color="primary"
                height="40"
                v-if="!creatingCarrierCriticism">
                Adicionar crítica
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-form
            ref="CarrierCriticismForm"
            v-if="creatingCarrierCriticism">
            <v-row
              class="mt-5">
              <v-col
                v-bind="colsProps">
                <v-autocomplete
                  :items="insuranceCarriers.filter(el => el.id === insuranceCarrierId)"
                  item-text="xipp_commercial_name"
                  item-value="id"
                  @change="setRuleName()"
                  outlined
                  dense
                  color="textPrimary"
                  item-color="textPrimary"
                  append-icon="fas fa-chevron-down"
                  :rules="manualCriticism.ruleId || manualCriticism.message || manualCriticism.field ? [] : [rule.required]"
                  validate-on-blur
                >
                </v-autocomplete>
              </v-col>
              <v-col
                v-bind="colsProps">
                <v-autocomplete
                  v-model="carrierCriticismForm.field"
                  :items="criticismFields"
                  item-text="label"
                  item-value="value"
                  hide-selected
                  clearable
                  dense
                  :loading="!criticismFields.length"
                  :disabled="!criticismFields.length"
                  label="Campo"
                  placeholder="Selecione"
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-autocomplete
                  v-model="manualCriticism.field"
                  :items="criticismFields"
                  item-text="label"
                  item-value="value"
                  hide-selected
                  clearable
                  dense
                  :loading="!criticismFields.length"
                  :disabled="!criticismFields.length"
                  label="Campo criticado*"
                  placeholder="Selecione"
                  persistent-hint
                  outlined
                  :rules="manualCriticism.ruleId || manualCriticism.message || manualCriticism.field ? [] : [rule.required]"
                  validate-on-blur
                  @change="manualCriticism.fieldValue = null"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-if="manualCriticism.field && manualCriticism.field.includes('date')"
                  v-model="manualCriticism.fieldValue"
                  label="Valor criticado"
                  placeholder="Digite aqui"
                  color="textPrimary"
                  dense
                  outlined
                  v-mask="'##/##/####'"
                  :rules="manualCriticism.fieldValue ? [rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid] : []"
                />
                <v-text-field
                  v-else
                  v-model="manualCriticism.fieldValue"
                  label="Valor criticado"
                  placeholder="Digite aqui"
                  color="textPrimary"
                  dense
                  outlined
                />
              </v-col>
            </v-row>
            <v-col class="pt-0 pr-0" align="end">
              <v-btn
                v-if="isEdit"
                class="mr-4"
                color="#3B495B"
                outlined
                @click="cleanFields()"
              >
                <template v-slot:[`item.rule`]="{ item }">
                  {{ item.rule ? item.rule : "Sem mensagem" }}
                </template>
                <template v-slot:[`item.message`]="{ item }">
                  {{ item.message ? item.message : "Sem mensagem" }}
                </template>
                <template v-slot:[`item.field`]="{ item }">
                  {{ item.field ? formatter.formatToTitleCase(item.field) : "Sem mensagem" }}
                </template>
                <template v-slot:[`item.fieldValue`]="{ item }">
                  {{ item.fieldValue ? checkFormatValue(item) : "Sem mensagem" }}
                </template>
                <template v-slot:[`item.attachments`]="{ item }">
                  <v-btn small icon color="textPrimary" @click="editItem(item)">
                    <v-icon>fas fa-edit</v-icon>
                  </v-btn>
                  <v-btn small icon color="textPrimary" @click="openConfirmationModal(item)">
                    <v-icon>fas fa-trash</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="manualCriticismList"
            :items-per-page="10"
            :loading="isLoadingTable"
            item-key="id"
            class="elevation-1"
          >
            <template v-slot:[`item.rule`]="{ item }">
              {{ item.rule ? item.rule : "Sem mensagem" }}
            </template>
            <template v-slot:[`item.message`]="{ item }">
              {{ item.message ? item.message : "Sem mensagem" }}
            </template>
            <template v-slot:[`item.field`]="{ item }">
              {{ item.field ? formatter.formatToTitleCase(item.field) : "Sem mensagem" }}
            </template>
            <template
              v-slot:[`item.field`]="{ item }">
              {{getFieldNameByValue(item.field) || '-'}}
            </template>
            <template
              v-slot:[`item.insuranceCarrierId`]="{ item }">
              {{ getInsuranceCarrierNameById(item.insuranceCarrierId) }}
            </template>
            <template
              v-slot:[`item.field`]="{ item }">
              {{getFieldNameByValue(item.field) || '-'}}
            </template>
            <template
              v-slot:[`item.actions`]="{ item }">
              <v-row
                class="d-flex justify-center">
                <v-icon
                  size="20"
                  color="primary"
                  @click="editItem(item)">
                  fas fa-edit
                </v-icon>
                <v-icon
                  size="20"
                  color="primary"
                  class="ml-2"
                  @click="openConfirmationModal(item)">
                  fas fa-trash
                </v-icon>
              </v-row>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-row class="mt-6">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-6 pa-7 btn-dialog"
              color="#3B495B"
              style="color: white"
              @click="onClickCloseModal()">
              Ok
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      timeout="10000"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>

    <ConfirmationModal
			ref="ConfirmationModal"
			@confirm="removeCriticismFromMovement"
      @close="cleanConfirmationData"
			:title="'Excluir Regra'"
			:description="'Tem certeza que deseja excluir esta regra?'"
      :color="'primary'"
		/>
  </div>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import MovementInsuranceCarrierReturnService from '@/services-http/sdi/MovementInsuranceCarrierReturnService';
import DataSourceService from '@/services-http/sdi/DataSourceService';
import lodash from 'lodash';
import sdiServiceManager from '@/services-http/sdi/SDIServiceManager';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import ConfirmationModal from '@/components/Modals/ConfirmationModal/ConfirmationModal.vue';


export default {
  name: 'ManualCriticismDialog',

  components: {
    ConfirmationModal
  },

  data: () => ({
    // headers: [
    //   {
    //     text: 'Regra',
    //     align: 'start',
    //     value: 'rule',
    //   },
    //   {
    //     text: 'Mensagem',
    //     align: 'center',
    //     value: 'message',
    //   },
    //   {
    //     text: 'Campo criticado',
    //     align: 'center',
    //     value: 'field',
    //   },
    //   {
    //     text: 'Valor criticado',
    //     align: 'center',
    //     value: 'fieldValue',
    //   },
    //   {
    //     text: 'Ações',
    //     align: 'center',
    //     value: 'attachments',
    //   },
    // ],
    carrierCriticism: null,
    carrierCriticismHeader: [
      {
        text: 'Operadora',
        align: 'center',
        value: 'insuranceCarrierId',
      },
      {
        text: 'Campo',
        align: 'center',
        value: 'classification',
      },
      // {
      //   text: 'Código',
      //   align: 'center',
      //   value: 'code',
      // },
      // {
      //   text: 'Descrição',
      //   align: 'center',
      //   value: 'description',
      // },
      {
        text: 'Mensagem',
        align: 'center',
        value: 'message',
      },
      // {
      //   text: 'Classificação SDI',
      //   align: 'center',
      //   value: 'sdi_classification',
      // },
      {
        text: 'Ações',
        align: 'center',
        value: 'actions',
      },
    ],
    classificationsOptions: [],
    carrierCriticismFormType: null,
    carrierCriticismForm: {
      id: null,
      insuranceCarrierId: null,
      field: null,
      criticismCode: null,
      description: null,
      message: null,
      carrierCriticism: null,
      classificationId: null,
    },
    carrierCriticismService: null,
    classificationsOptions: [],
    itemsPerPage: 10,
    rulesHeader: [
      {
        text: 'Nome',
        align: 'center',
        value: 'name',
      },
      {
        text: 'Ação',
        align: 'center',
        value: 'actions',
      },
    ],
    critics: [],
    manualCriticism: {
      id: null,
      ruleId: 0,
      rule: null,
      cardNumber: null,
      movementRecordId: 0,
      returnType: 'FAIL',
      message: null,
      criticismType: null,
      field: null,
      fieldValue: null,
      movementInsuranceCarrierId: 0,
    },
    dialog: { show: false },
    snackbar: {
      show: false,
      text: '',
      type: '',
    },
    formRules: false,
    isEdit: false,
    manualCriticismCurrent: [],
    isLoadingTable: false,
    creatingCarrierCriticism: false,
    isModified: false,
    criticismFields: [],
    rules: [],
    manualCriticismList: [],
    creatingClassification: false,
    itemToDelete: null,
    carrierCriticismProps: {
      dense: true,
      outlined: true,
      color: "primary",
      validateOnBlur: true,
    },
    colsProps: {
      cols: "12",
			sm: "6",
			md: "4",
			lg:"3",
      class: "py-0"
    }
  }),
  props: {
    show: { type: Boolean },
    movementRecordId: { type: Number },
    movementInsuranceCarrierId: { type: Number },
    insuranceCarrierId: { type: Number },
    insuredName: { type: String },
    propsRules: { type: Array },
    isFreeMovement: { type: Boolean },
  },

  mixins: [
    InsuranceCarriersMixin
  ],

  watch: {
    show: {
      async handler(val) {
        this.dialog.show = val;
        if (val) {
          await this.getInsuranceCarriers();
          this.manualCriticism.movementRecordId = this.movementRecordId;
          this.manualCriticism.movementInsuranceCarrierId = this.movementInsuranceCarrierId;
          this.manualCriticism.insuranceCarrierId = this.insuranceCarrierId;
          this.isModified = false;
          this.resetCreationCarrierCriticism();
          await this.loadCriticismField();
          await this.loadManualCriticisms();
          await this.getCritics();
        }
      },
      deep: true,
      immediate: true,
    },
    propsRules(val) {
      if (val) {
        this.rules = val;
      }
    },
    manualCriticismList(val) {
      if (val) {
        this.manualCriticismCurrent = lodash.cloneDeep(this.manualCriticismList);
      }
    },
  },
  methods: {
    getFieldNameByValue(value) {
      const fieldFounded = this.criticismFields.find((field) => field.value === value);
      if (fieldFounded) {
        return fieldFounded.label;
      }
      return null;
    },
    cleanCritic() {
      this.carrierCriticism = null;
      this.$forceUpdate();
    },
    filterCritics(item, queryText, itemText) {
      if (!queryText) return;

      const textToSearch = queryText.toLowerCase();

      return (
        (this.getInsuranceCarrierNameById(item?.insuranceCarrierId)?.toLowerCase() ?? '').includes(textToSearch)
        || (item?.description?.toLowerCase() ?? '').includes(textToSearch)
        || (item?.field?.toLowerCase() ?? '').includes(textToSearch)
        || (item?.criticismCode?.toLowerCase() ?? '').includes(textToSearch)
        || (item?.message?.toLowerCase() ?? '').includes(textToSearch)
      );
    },
    startCreateClassification() {
      this.creatingClassification = true;
    },
    finishCreateClassification() {
      this.creatingClassification = false;
      this.$refs.ruleClassificationForm.reset();
      this.$refs.ruleClassificationForm.resetValidation();
    },
    completeCreateClassification() {
      this.$emit('loadRules');
      // this.manualCriticism.ruleId = 
      this.finishCreateClassification();
    },
    async addCriticism() {
      // this.checkFieldType();
      const listAux = [];

      const obj = {
        // id: null,
        // ruleId: null,
        // rule: this.carrierCriticism.message,
        // cardNumber: null,
        // criticismType: null,
        // fieldValue: null,
        movementRecordId: this.manualCriticism.movementRecordId,
        criticismCarrierId: this.carrierCriticism ? this.carrierCriticism.id : null,
        returnType: 'FAIL',
        message: this.carrierCriticism.message,
        field: this.carrierCriticism.field,
        movementInsuranceCarrierId: this.manualCriticism.movementInsuranceCarrierId,
      }
      listAux.push(obj);
      if (!this.isEdit) {
        this.createCriticism(listAux);
      } else {
        this.updateCriticism(listAux);
      }
      this.cleanFields();
      this.resetCreationCarrierCriticism();
      await this.getCritics();
    },
    async setCriticism() {
      await this.addCarrierCriticism();
      // this.checkFieldType();
      const listAux = [];

      const obj = {
        id: this.carrierCriticismForm
          && this.carrierCriticismForm.criticismCarrierId
          ? this.carrierCriticismForm.criticismCarrierId : null,
        // ruleId: null,
        // rule: this.carrierCriticism.message,
        // cardNumber: null,
        // criticismType: null,
        // fieldValue: null,
        movementRecordId: this.manualCriticism.movementRecordId,
        criticismCarrierId: this.carrierCriticismForm.id,
        returnType: 'FAIL',
        message: this.carrierCriticismForm.message,
        carrierDescription: this.carrierCriticismForm.description,
        field: this.carrierCriticismForm.field,
        movementInsuranceCarrierId: this.manualCriticism.movementInsuranceCarrierId,
      }
      listAux.push(obj);
      if (!this.isEdit) {
        this.createCriticism(listAux);
      } else {
        this.updateCriticism(listAux);
      }
      setTimeout(async () => {
        this.cleanFields();
        this.resetCreationCarrierCriticism();
        await this.getCritics();
      }, 500)
    },
    getClassifications() {
      this.carrierCriticismService.FindAll()
        .then((response) => {
          if (response && response.data) {
            this.classificationsOptions = response.data;
            this.mapClassifications();
          }
      });
    },
    indexValidation(item){
      return this.critics.findIndex(
        el => el.id === item.id
          && el.field === item.field
          && el.insuranceCarrierId === item.insuranceCarrierId
          && el.criticismCode === item.criticismCode
          && el.classificationId === item.classificationId
        ) === 0;
    },
    formatedClassification(item) {
      const defaultOption = {
        text: 'Não classificado',
        icon: 'mdi mdi-progress-clock',
      };
      const result = this.classificationsOptions.find((element) => element && element.id === item.classificationId) || defaultOption;

      if (item.notClassified) {
        return defaultOption;
      }

      return result;
    },
    getClassificationNameById(classificationId) {
      const result = this.classificationsOptions.find((element) => element && element.id === classificationId);

      return result.text;
    },
    mapClassifications() {
      if (this.classificationsOptions) {
        this.classificationsOptions = this.classificationsOptions.map((classification) => {
          let icon = '';
          let text = '';

          switch (classification.name.toLowerCase()) {
            case 'external':
              text = 'Restritiva Externa';
              icon = 'mdi mdi-account-cog-outline';
              break;
            case 'internal':
              text = 'Restritiva Interna';
              icon = 'mdi mdi-cancel';
              break;
            case 'informative':
              text = 'Informativa';
              icon = 'mdi mdi-information-slab-circle-outline';
              break;
            case 'decisive':
              text = 'Decisiva';
              icon = 'far fa-question-circle';
              break;
            case 'inactive':
              text = 'Inativa';
              icon = 'mdi mdi-minus-circle-outline';
              break;
            default:
              break;
          }

          return {
            text,
            icon,
            id: classification.id,
          };
        });
      }
    },
    async loadManualCriticisms() {
      this.isLoadingTable = true;
      this.manualCriticismList = [];
      this.movementInsuranceCarrierReturnService.FindByMovementRecordId(this.movementRecordId).then((response) => {
        if (response && response.data && response.data.content.length > 0) {
          const manualCriticismFails = response.data.content.filter((m) => m.returnType === 'FAIL');
          this.manualCriticismList = manualCriticismFails;
          this.isLoadingTable = false;
        }
      }).catch(() => {
        this.isLoadingTable = false;
      }).finally(() => {
        this.isLoadingTable = false;
      });
    },
    async loadCriticismField() {
      await this.dataSourceService.FindAll().then((response) => {
        const fields = response.data.find((item) => item.value === 'movimentacao');
        this.criticismFields = fields.columns.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
      });
    },
    resetCreationCarrierCriticism() {
      if (this.$refs.CarrierCriticismForm) this.$refs.CarrierCriticismForm.reset();
      this.carrierCriticismFormType = null;
      this.creatingCarrierCriticism = false;
      this.carrierCriticism = null;
    },
    startCreationCarrierCriticism() {
      this.isEdit = false;
      this.carrierCriticismFormType = 'create';
      this.creatingCarrierCriticism = true;
    },
    startUpdateCarrierCriticism() {
      this.carrierCriticismFormType = 'edit';
      this.creatingCarrierCriticism = true;
    },
    async addCarrierCriticism() {
      if (!this.$refs.CarrierCriticismForm.validate()) return;

      this.loadingCriticismForm = true;

      if (!this.validationFields()) {
        this.loadingCriticismForm = false;

        this.customizeSnackbarMessage(
          'error',
          'Informe ao menos "Código da crítica", "Descrição" ou "Mensagem" para prosseguir'
        );

        return;
      }

      // if (this.carrierCriticismFormType === 'classify') {
      //   this.requestClassify(form);
      // }

      if (this.carrierCriticismFormType === 'create') {
        await this.requestCreateClassification();
      }

      if (this.carrierCriticismFormType === 'edit') {
        await this.requestEditClassification();
      }

      // if (this.carrierCriticismFormType === 'classify') {
      //   this.requestClassify(form);
      // }

      if (this.carrierCriticismFormType === 'create') {
        await this.requestCreateClassification();
      }

      if (this.carrierCriticismFormType === 'edit') {
        await this.requestEditClassification();
      }
    },
    validationFields() {
      const { criticismCode, description, message } = this.carrierCriticismForm;

      if (criticismCode || description || message) {
        return true;
      }
    },
    validationFields() {
      const { criticismCode, description, message } = this.carrierCriticismForm;

      if (criticismCode || description || message) {
        return true;
      }
      return false;
    },
    async requestCreateClassification() {
      await this.carrierCriticismService
        .Insert(this.carrierCriticismForm)
        .then((res) => {
          this.critics.push(res.data);

          setTimeout(() => {
            this.carrierCriticism = res.data;

            this.addCriticism();
          }, 500)

          this.customizeSnackbarMessage(
            'success',
            'Classificação criada com sucesso'
          );

          this.resetCreationCarrierCriticism();
        })
        .catch(() => {
          this.customizeSnackbarMessage(
            'error',
            'Erro ao criar classificação'
          );
        })
        .finally(() => {
          this.loadingCriticismForm = false;
        });
    },
    async requestEditClassification() {
      let params = this.carrierCriticismForm;

      params.isDefault = false;

      await this.carrierCriticismService
        .Update(this.carrierCriticismForm)
        .then((res) => {
          this.customizeSnackbarMessage(
            'success',
            'Classificação editada com sucesso'
          );

        })
        .catch(() => {
          this.customizeSnackbarMessage(
            'error',
            'Erro ao editar classificação'
          );
        }).finally(() => {
          this.loadingCriticismForm = false;
        });
    },
    createCriticism(listAux) {
      this.movementInsuranceCarrierReturnService.Save(listAux, this.isFreeMovement).then(() => {
        this.loadManualCriticisms();
        this.isModified = true;
      });
    },
    updateCriticism(listAux) {
      this.movementInsuranceCarrierReturnService.Save(listAux, this.isFreeMovement).then(() => {
        this.loadManualCriticisms();
        this.isModified = true;
      });
    },
    async removeCriticismFromMovement() {
      this.isLoadingTable = true;
      await this.movementInsuranceCarrierReturnService.Delete(this.itemToDelete.id)
        .then((response) => {
          this.loadManualCriticisms();
        }).catch(() => {
        }).finally(() => {
          this.isLoadingTable = false;
          this.isModified = true;
        });
    },
    async removeCriticismFromMovement() {
      this.isLoadingTable = true;
      await this.movementInsuranceCarrierReturnService.Delete(this.itemToDelete.id)
        .then((response) => {
          this.loadManualCriticisms();
        }).catch(() => {
        }).finally(() => {
          this.isLoadingTable = false;
        });
    },
    checkFieldType() {
      const formatField = (this.manualCriticism.field.includes('date') && this.manualCriticism.fieldValue) ? this.formatter.formatDateBRtoString(this.manualCriticism.fieldValue) : this.manualCriticism.fieldValue;
      this.manualCriticism.fieldValue = formatField;
    },
    setRuleName() {
      const ruleName = this.rules.find((item) => item.id === this.manualCriticism.ruleId);
      if (ruleName) {
        this.manualCriticism.rule = ruleName.name;
      }
    },
    editItem(item) {
      if (item) {
        this.isEdit = true;
      } else {
        this.isEdit = false;
      }

      this.carrierCriticismForm = {
        insuranceCarrierId: item.insuranceCarrierId,
        criticismCode: item.criticismCode,
        description: item.criticismDescription,
        carrierCriticism: item.carrierCriticism,
        classificationId: item.classification === "EXTERNAL" ? 1 : 2,

        id: item.criticismCarrierId,
        criticismCarrierId: item.id,
        ruleId: item.ruleId,
        rule: item.rule,
        cardNumber: item.cardNumber,
        movementRecordId: item.movementRecordId,
        returnType: 'FAIL',
        message: item.message,
        criticismType: item.criticismType,
        field: item.field,
        fieldValue: item.field && item.field.includes('date') ? this.formatter.formatDate(item.fieldValue) : item.fieldValue,
        movementInsuranceCarrierId: item.movementInsuranceCarrierId,
      };

      this.startUpdateCarrierCriticism();
    },
    deleteItem() {
      this.cleanConfirmationData();
    },
    openConfirmationModal(item) {
      if (item.userId === 0) {
        this.customizeSnackbarMessage(
          'error',
          'Esta crítica não pode ser apaga, pois é uma crítica da API.'
        );

        return;
      }

      if (item.classification === 'INTERNAL') {
        this.customizeSnackbarMessage(
          'error',
          'Críticas internas não podem ser removidas.'
        );

        return;
      }
      this.itemToDelete = item;
      this.$refs.ConfirmationModal.open()
    },
    cleanConfirmationData() {
      this.itemToDelete = null;
    },
    deleteItem() {
      this.cleanConfirmationData();
    },
    openConfirmationModal(item) {
      this.itemToDelete = item;
      this.$refs.ConfirmationModal.open()
    },
    cleanConfirmationData() {
      this.itemToDelete = null;
    },
    deleteItem() {
      this.cleanConfirmationData();
    },
    openConfirmationModal(item) {
      this.itemToDelete = item;
      this.$refs.ConfirmationModal.open()
    },
    cleanConfirmationData() {
      this.itemToDelete = null;
    },
    onClickCloseModal() {
      this.cleanFields();
      this.$emit('close', this.isModified);
    },
    cleanFields() {
      this.carrierCriticismForm = {
        insuranceCarrierId: null,
        criticismCode: null,
        description: null,
        carrierCriticism: null,
        classificationId: null,

        id: null,
        ruleId: null,
        criticismType: null,
        rule: null,
        cardNumber: null,
        movementRecordId: this.movementRecordId,
        returnType: 'FAIL',
        message: null,
        field: null,
        fieldValue: null,
        movementInsuranceCarrierId: this.movementInsuranceCarrierId,
      };
      this.isEdit = false;
      this.critics = [];
      this.manualCriticismList = [];
      this.carrierCriticism = null;
    },
    checkFormatValue(item) {
      if (item && item.field && item.field.includes('date')) {
        return this.formatter.formatDate(item.fieldValue);
      }
      return item.fieldValue;
    },
    validationManualCriticisms() {
      const criticismModified = this.manualCriticismCurrent.find((element) => element.id === this.carrierCriticismForm.id);
      if (criticismModified) {
        if (criticismModified.ruleId !== this.manualCriticism.ruleId || criticismModified.message !== this.manualCriticism.message || criticismModified.field !== this.manualCriticism.field || criticismModified.fieldValue !== this.manualCriticism.fieldValue) {
          return true;
        }
      }
      return false;
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
    async getCritics() {
      this.loadingCritics = true;

      const searchParams = new URLSearchParams();
      searchParams.append('insuranceCarrierIds', this.manualCriticism.insuranceCarrierId);
      searchParams.append('size', 999);
      searchParams.append('withGeneric', true);

      await this.carrierCriticismService
        .FindBy(searchParams)
        .then((response) => {
          if (response && response.data && response.data.content) {
            this.critics = response.data.content.sort((a, b) => (a.description > b.description ? 1 : -1));
          }
        })
        .catch(() => {
          this.customizeSnackbarMessage(
            'error',
            'Erro ao buscar críticas'
          );
        })
        .finally(() => {
          this.loadingCritics = false;
        });
    },
  },
  computed: {
    criticsFiltered() {
      const manualCriticism = this.manualCriticismList.map(el => el.criticismCarrierId)
      let criticsFiltered =  this.critics.filter(el => !manualCriticism.includes(el.id))

      criticsFiltered = criticsFiltered.sort((a, b) => {
        const firstCarrier = this.getInsuranceCarrierNameById(a.insuranceCarrierId) || '';
        const secondCarrier = this.getInsuranceCarrierNameById(b.insuranceCarrierId) || '';
        return secondCarrier.localeCompare(firstCarrier);
      });

      return criticsFiltered;
    },
  },
  created() {
    this.formatter = new Formatters();
    this.rule = new Rules();
    this.movementInsuranceCarrierReturnService = new MovementInsuranceCarrierReturnService();
    this.dataSourceService = new DataSourceService();
    this.dialog.show = this.show;
    this.carrierCriticismService = sdiServiceManager.getCarrierCriticismService();
  },
  mounted() {
    this.getClassifications();
  }
};
</script>

<style scoped>
.btn-dialog {
  width: 200px;
  font-size: 16px;
}
</style>
